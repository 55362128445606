import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import './App.css';
import data from './data.json';

function App() {
  return (
    <>
      <div style={{ height: "16px" }} />
      <Container fluid>
        <Card bg="dark" text="white">
          <Card.Body>
            <big><big><big><b>project overview</b></big></big></big>
            <Button href={data.profile} variant="info" className="float-end">github profile</Button>
          </Card.Body>
        </Card>
        <div style={{ height: "14px" }} />
        <Projects />
      </Container>
    </>
  );
}

const Projects: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        {data.list.map((item: any) => {
          return (
            <Col xs={0} md={(item.repo !== undefined) ? 3 : 6}>
              <Card bg="secondary" text="white">
                <Card.Header>
                  <h5><b>{item.title}</b></h5>
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    {(item.repo !== undefined) ?
                      <>
                        <Row>
                          <Col className="d-grid gap-2">
                            <Button href={item.repo} variant="dark">repo</Button>
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                        <Row>
                          <Col md={6} className="d-grid gap-2">
                            <Button href={item.link.frontend} variant="dark">frontend</Button>
                          </Col>
                          <Col md={6} className="d-grid gap-2">
                            <Button href={item.link.backend} variant="dark">backend</Button>
                          </Col>
                        </Row>
                        <div style={{ height: "14px" }} />
                        <Row>
                          <Col md={12} className="d-grid gap-2">
                            <Button href={item.link.backendDocumentation} variant="info">backend documentation</Button>
                          </Col>
                        </Row>
                      </>
                    }
                  </Container>
                </Card.Body>
              </Card>
              <div style={{ height: "14px" }} />
            </Col>
          )
        })}
      </Row>
    </Container>
  );
}

export default App;
